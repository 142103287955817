//修改银行库卡短信验证
<template>
  <div class="circle">
    <p class="title">短信验证码将发送至手机 {{ namephone }}</p>
    <div>
      <el-input
        placeholder="请输入短信验证码"
        v-model="input2"
        oninput="value=value.replace(/[^\d]/g,'')"
      >
        <template slot="append"
          ><span class="send" v-if="sendfalg" @click="send">发送短信验证码</span
          ><span style="color:red"  v-else>{{ msg }}</span>
        </template>
      </el-input>
    </div>
    <div class="footbnt">
      <button @click="$router.back()">返回</button>
      <el-button class="primay" @click="validate" :loading="nextbol">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { sms_send, sms_validate } from '../api/index'
export default {
  props:{
    identity:[String]
  },
  created () {
    this.phone = this.$store.state.user.mobile
    if (this.phone) {
      this.namephone = this.phone.split('')
      this.namephone.splice(3, 4, "****")
      this.namephone = this.namephone.join('')
    }
  },
  data () {
    return {
      input2: '',
      sendfalg: true,
      msg: '',
      phone: '',
      namephone: '',
    //节流
      throttlesEnd:true,
      nextbol:false,
    }
  },
 computed:{
     zone: function (){
         return this.$store.state.user.zone
     },

 },
  methods: {
    jump () {
      this.$router.push('setcard')
    },
    async send () {
        if(!this.throttlesEnd){
            return
        }
        this.throttlesEnd=false;
      //   获取验证码
      let p = {
        zone: this.zone,
        phone: this.phone,
        type: 7,
        cs: Date.parse(new Date())
      }
      const res = await sms_send(p)
      this.throttlesEnd=true;
      if (res.code != 0) {
        return
      }
      this.sendfalg = false
      let i = 60
      this.msg = `剩余60s`
      let timer = setInterval(() => {
        if (i > 1) {
          i--
          this.msg = `剩余${i}s`
        } else {
          this.sendfalg = true,
            clearInterval(timer)
        }
      }, 1000)
    },
    //  短信校验
    validate () {
      let p = {
        zone:this.zone,
        phone: this.phone,
        type: 7,
        acode:this.input2
      }
      this.nextbol=true;
      sms_validate(p).then(res => {
           this.nextbol=false;
        if(res.httpStatus==0 || res.code === 0){
           this.$router.push(`/setbankcard/setcard/${this.identity}`);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.circle {
  width: 360px;
  margin: 0px auto;
  .title {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    margin-bottom: 24px;
  }
  ::v-deep .el-input-group {

    .el-input__inner {
      border: 1px solid #e8e8e8;
    }
    .el-input-group__append {
      padding: 0 10px;
      .send {
        color: red;
        cursor: pointer;
      }
    }
  }
  .footbnt {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    button {
      cursor: pointer;
      width: 170px;
      height: 40px;
      border: none;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
     color: #333333;
      line-height: 14px;
    }
    .primay {
      background: rgba(255, 0, 77, 0.5);
      color: #ffffff;
      //   margin-left: 19px;
    }
    .primay:hover {
      background: rgba(255, 0, 77, 1);
    }
  }
}
</style>
